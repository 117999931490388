/*

--- 01 TYPOGRAPHY SYSTEM ---

- FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font-weights: 
Default: 400
Medium: 500
Bold: 700


- Line heights: 


- Letter spacing



--- 02 COLOURS ---

- Primary: #c5b29f

- Tints: 
#e2d9cf

- Shades: 
#b1a08f - btn-hover

- Accents: 

- Greys: 
#555 - standard color for body

--- 03 IMAGES ---


--- 04 ICONS ---


--- 05 SHADOWS---


--- 06 BORDER RADIUS ---



--- 07 WHITESPACE --- 

- SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  color: #555;
  /* background-color: #333; */
}

.container {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

/* Grids */
.grid {
  display: grid;
  row-gap: 8rem;
  column-gap: 4.8rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}
.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

/* Headings */
.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  margin-bottom: 3.2rem;
}
.heading-primary {
  font-size: 5.2rem;
  letter-spacing: -0.5px;
  line-height: 1.05;
}

.heading-secondary {
  font-size: 4.4rem;
  color: #333;
  letter-spacing: -0.5px;
  line-height: 1.05;
}
.heading-tertiary {
  font-size: 3.2rem;
  color: #333;
  line-height: 1.5;
  margin-bottom: 0;
}

.heading-tag {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #b1a08f;
  letter-spacing: 0.075rem;
  margin-bottom: 1.6rem;
}

/* Buttons */
.btn:link,
.btn:visited {
  font-size: 2rem;
  font-weight: 600;
  background-color: #c5b29f;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 3.2rem;
  border-radius: 7px;

  transition: all 0.3s;
}

.btn:hover,
.btn:active {
  background-color: #b1a08f;
}

*:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rgba(226, 217, 207, 0.5);
}
/* ************************** */
/* HELPER CLASSES */
/* ************************** */

.margin-right-sm {
  margin-right: 3.2rem;
}

.margin-top-sm {
  margin-top: 3.2rem;
}
